import '../../assets/styles/reports/reports.scss';
import MenuItem from "@mui/material/MenuItem";
import {Button, Card, CardContent, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {allCategoryList} from "../../store/slices/category";
import {allSubCategoryList} from "../../store/slices/subCategory";
import {useDispatch, useSelector} from "react-redux";
import {fetchReportData, getMonthOptionList} from "../../store/slices/reports";
import RecentTransactions from "../../components/transaction/RecentTransactions";
import {calculateTotalSpentSoFar, getSubCategoryTitle, getTitleFromCategory} from "../../utils/utility.service";
import ApexBarChart from "../../components/reports/charts/ApexBarChart";
import CircularLoading from "../../components/splash/CircularLoading";
import TransactionSimpleItem from "../../components/transaction/TransactionSimpleItem";
import {DEFAULT_CURRENCY} from "../../utils/constants";
import Typography from "@mui/material/Typography";
import SubCategoryStats from "../../components/reports/SubCategoryStats";
import ListIcon from '@mui/icons-material/List';
import moment from "moment";

const ReportPage = () => {

    const dispatch = useDispatch();
    const {defaultWallet: wallet} = useSelector((state) => state.wallet);
    const {settings: userSettings} = useSelector((state) => state.userProfile);
    const {categoryList} = useSelector(allCategoryList);
    const {subCategoryList} = useSelector(allSubCategoryList);

    const [monthOptionList, setMonthOptionList] = useState(null);
    const [selectValue, setSelectValue] = useState("0");
    const [totalAmountSpentMonthwise, setTotalAmountSpentMonthwise] = useState(0);
    const [reportMonthlyData, setReportMonthlyData] = useState(null);
    const [categoryChartData, setCategoryChartData] = useState(null);
    const [monthlyChartData, setMonthlyChartData] = useState(null);
    const [topExpensesData, setTopExpensesData] = useState([]);
    const [showAllTransaction, setShowAllTransaction] = useState(false);
    let currentYear = (new Date()).getFullYear();

    useEffect(() => {
        if (wallet && !monthOptionList) {
            dispatch(getMonthOptionList({userId: wallet.userId, walletId: wallet.id}))
                .unwrap()
                .then((response) => {
                    setMonthOptionList(response);

                    if (response.length > 0) {
                        let defaultSelectedValue = response[0].code;
                        setSelectValue(defaultSelectedValue.toString());
                        loadReportData(defaultSelectedValue);
                    }
                })
                .catch(() => {
                })
        }
    }, [monthOptionList]);

    const handleChange = (event) => {

        const {name, value} = event.target;
        setSelectValue(value);
        loadReportData(value);
    }

    const loadReportData = (value) => {
        let filterValue;
        value = value.toString();
        debugger;
        if (value) {
            switch (value.length) {
                case 1:
                    filterValue = {'lastXMonths': {'enable': true, 'value': value}};
                    break;
                case 4:
                    filterValue = {'year-wise': {'enable': true, 'value': value}}; // yyyy
                    break;
                case 6:
                    filterValue = {'month-wise': {'enable': true, 'value': value}};
                    break;
            }
        }

        if (wallet && value && filterValue) {
            dispatch(fetchReportData({walletId: wallet.id, filter: filterValue}))
                .unwrap()
                .then((response) => {
                    setReportMonthlyData(response);

                    categoryChartConfig(response);
                    lastMostExpensiveItems(response, value);

                    if(value.length <= 4) { // Yearly data YYYY or last 3 or 6 months
                        monthlyChartConfig(response,);
                    } else {
                        setMonthlyChartData(null);
                    }
                    setTotalAmountSpentMonthwise(calculateTotalSpentSoFar(response));
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    const lastMostExpensiveItems = (monthlyData, currentSelectedValue) => {

        if (monthlyData) {
            monthlyData.map(transaction => {
                if (transaction.isExclude) {
                    return; // skipping exclude items in the report
                }

            })
            let monthlyDataModified = monthlyData.slice().sort((a, b) => b.amount - a.amount);
            monthlyDataModified = monthlyDataModified.filter(trn => trn.isExclude != true);

            if (currentSelectedValue.length > 4)
                setTopExpensesData(monthlyDataModified.slice(0, 5)) // monthly top 5 only
            else
                setTopExpensesData(monthlyDataModified.slice(0, 10)) // yearly and quarterly top 10
        }
    }

    const btnClickShowTransaction = () => {
        setShowAllTransaction(!showAllTransaction);
    };

    const monthlyChartConfig = (allTransactions) => {

        let monthlyMap = []
        if (allTransactions) {
            allTransactions.map(transaction => {

                if (transaction.isExclude) {
                    return; // skipping exclude items in the report
                }

                let monthIndex = moment(transaction.paidOn).month();
                let indexFound = monthlyMap.findIndex(data => data.monthIndex === monthIndex);
                if (monthlyMap[indexFound]) {
                    monthlyMap[indexFound] = {
                        ...monthlyMap[indexFound],
                        amount: Number(monthlyMap[indexFound].amount) + Number(transaction.amount)
                    }
                } else {
                    monthlyMap.push({
                        monthIndex: monthIndex,
                        title: moment(transaction.paidOn).format("MMM"),
                        amount: transaction.amount
                    });
                }
            })

            let chartCategory = [];
            if (monthlyMap) {
                monthlyMap.sort((a, b) => a.monthIndex - b.monthIndex);

                let dataSeries = [];
                monthlyMap.map((item) => {
                    chartCategory.push(item.title);
                    dataSeries.push(item.amount);
                });

                let chartData = {
                    categories: chartCategory, chartSeries: [{
                        name: 'Category', data: dataSeries
                    }]
                }
                setMonthlyChartData(chartData);
            }
        }
    }

    const categoryChartConfig = (monthlyData) => {
        if (monthlyData) {

            let categoryMap = [];

            monthlyData.map(transaction => {
                if (transaction.isExclude) {
                    return; // skipping exclude items in the report
                }

                let indexFound = categoryMap.findIndex(data => data.categoryId === transaction.categoryId);
                if (categoryMap[indexFound]) {
                    categoryMap[indexFound] = {
                        ...categoryMap[indexFound],
                        amount: Number(categoryMap[indexFound].amount) + Number(transaction.amount)
                    }
                } else {
                    categoryMap.push({
                        categoryId: transaction.categoryId,
                        category: getTitleFromCategory(transaction.categoryId, categoryList),
                        amount: transaction.amount
                    });
                }
            });


            let chartCategory = [];
            if (categoryMap) {
                categoryMap.sort((a, b) => b.amount - a.amount);

                let dataSeries = [];
                categoryMap.map((item) => {
                    chartCategory.push(item.category);
                    dataSeries.push(item.amount);
                });

                let chartData = {
                    categories: chartCategory, chartSeries: [{
                        name: 'Category', data: dataSeries
                    }]
                }

                setCategoryChartData(chartData);
                //setCategoryChartData(categoryMap.slice(0, 5));
            }
        }
    }

    return (<div className="report-page-container header-top-padding">
        <div className="form-header">

            <TextField select label="Select"
                       className="field-select"
                       label="View report"
                       value={selectValue}
                       name="Month"
                       onChange={handleChange}
            >
                <MenuItem value="0"> Select option</MenuItem>)
                {monthOptionList ? monthOptionList.map((option) => {
                    return (<MenuItem value={option.code}
                                      id={option.code}
                                      key={option.code}>
                        {option.text}
                    </MenuItem>)
                }) : ""}
                <MenuItem value={currentYear}> Current Year</MenuItem>)
                <MenuItem value="2"> Last 3 months</MenuItem>)
                <MenuItem value="5"> Last 6 months</MenuItem>)
            </TextField>
        </div>

        <div className="total-amount-spent">
            <div>
                <span>Total amount spent</span>
                <span>{userSettings ? userSettings.currency : ''} {totalAmountSpentMonthwise.toFixed(2)}</span>
            </div>
        </div>

        <div className="categoryChart">
            {categoryChartData ? <ApexBarChart
                chartTitle="Expense by category"
                data={categoryChartData}
                xFieldValue="category"
                yFieldValue="amount"
            /> : ''}
        </div>
        {
            monthlyChartData ?
                <div className="categoryChart">
                    {categoryChartData ? <ApexBarChart
                        chartTitle="Monthly expenses"
                        data={ monthlyChartData }
                        xFieldValue="Month"
                        yFieldValue="amount"
                    /> : ''}
                </div> : ''
        }


        <div className="stats-section">
            <div className="top-expenses">
                <Card title="Title" sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">

                            {
                                (selectValue.length > 4) ? 'Top 5 Expenses' : 'Top 10 Expenses'
                            }

                        </Typography>
                        {
                            (topExpensesData && topExpensesData.length > 0) ?

                                topExpensesData.map(transaction => {

                                    return <div>
                                        <TransactionSimpleItem
                                            transaction={transaction}
                                            categoryList={categoryList}
                                            subCategoryTitle={getSubCategoryTitle(transaction.subCategoryId, subCategoryList, transaction.spendAt)}
                                            currency={userSettings ? userSettings.currency : DEFAULT_CURRENCY}/>
                                    </div>
                                }) : ""
                        }
                    </CardContent>
                </Card>
            </div>
            <div className="sub-catgory-expenses">
                <SubCategoryStats
                    transactionList={reportMonthlyData}
                    categoryList={categoryList}
                    subCategoryList={subCategoryList}
                    currency={userSettings ? userSettings.currency : DEFAULT_CURRENCY}
                />
            </div>
        </div>


        <div className="all-transactions-section">
            <div className="btn-transaction">
                <Button className="add-reminder-btn" variant="contained" startIcon={<ListIcon/>}
                        onClick={btnClickShowTransaction}
                >
                    Show all Transactions
                </Button>
            </div>
            {reportMonthlyData ? reportMonthlyData.length > 0 ?
                    (showAllTransaction ? <RecentTransactions transactionList={reportMonthlyData}/> : '')
                    :
                    <span className="no-recent-transaction">No recent transactions</span> :
                <CircularLoading enableFlag={true}/>}
        </div>
    </div>)
}

export default ReportPage;